import api from '../services/api';

const data = {
    "agency" : [
        {
            "region": "Алтайский край",
            "person": "Парадовский Борис Климентьевич"
        },
        {
            "region": "Белгородская область",
            "person": "Коваль Владимир Николаевич"
        },
        {
            "region": "Брянская область",
            "person": "Павлова Ольга Кузьминична"
        },
        {
            "region": "Владимирская область",
            "person": "Болотаев Зураб Солтанович"
        },
        {
            "region": "Волгоградская область",
            "person": "Аникеев Павел Геннадьевич"
        },
        {
            "region": "Вологодская область",
            "person": "Стародубцев Владимир Владимирович"
        },
        {
            "region": "Воронежская область",
            "person": "Гончар Юрий Александрович"
        },
        {
            "region": "Забайкальский край",
            "person": "Баясхаланов Цыпыл Цыдыпович"
        },
        {
            "region": "Ивановская область",
            "person": "Черков Александр Леонидович"
        },
        {
            "region": "Иркутская область",
            "person": "Лобачев Сергей Евгеньевич"
        },
        {
            "region": "Калининградская область",
            "person": "Шаманов Сергей Хасанович"
        },
        {
            "region": "Калужская область",
            "person": "Хряпин Юрий Иванович"
        },
        {
            "region": "Карачаево-Черкесская Республика",
            "person": "Батчаев Башир Анзорович"
        },
        {
            "region": "Кемеровская область",
            "person": "Рузаев Андрей Владимирович"
        },
        {
            "region": "Кировская область",
            "person": "Фалевская Марина Анатольевна"
        },
        {
            "region": "Краснодарский край",
            "person": "Неженцева Ирина Николаевна"
        },
        {
            "region": "Красноярский край",
            "person": "Кочергина Наталья Владимировна"
        },
        {
            "region": "Курганская область",
            "person": "Захаров Сергей Михайлович"
        },
        {
            "region": "Ленинградская область",
            "person": "Горбунов Сергей Сергеевич"
        },
        {
            "region": "Липецкая область",
            "person": "Борцов Евгений Михайлович"
        },
        {
            "region": "Московская область",
            "person": "Соин Дмитрий Юрьевич"
        },
        {
            "region": "Нижегородская область",
            "person": "Тихомиров Павел Владимирович"
        },
        {
            "region": "Новгородская область",
            "person": "Вагин Михаил Витальевич"
        },
        {
            "region": "Новосибирская область",
            "person": "Орел Игорь Валерьевич"
        },
        {
            "region": "Новосибирское городское отделение",
            "person": "Осипов Эдуард Геннадьевич"
        },
        {
            "region": "Омская область",
            "person": "Жданов Денис Николаевич"
        },
        {
            "region": "Орловская область",
            "person": "Сенцов Игорь Иванович"
        },
        {
            "region": "Пензенская область",
            "person": "Каширов Александр Анатольевич"
        },
        {
            "region": "Республика Саха-Якутия",
            "person": "Слепцова Елена Борисовна"
        },
        {
            "region": "Республика Дагестан",
            "person": "Омаров Казбек Шейхович"
        },
        {
            "region": "Республика Адыгея (Адыгея)",
            "person": "Джоджуа Ирина Леонидовна"
        },
        {
            "region": "Республика Башкортостан",
            "person": "Гатин Равиль Ирикович"
        },
        {
            "region": "Республика Бурятия",
            "person": "Ермоев Владимир Витальевич"
        },
        {
            "region": "Республика Калмыкия",
            "person": "Жихарев Александр Юрьевич"
        },
        {
            "region": "Республика Крым",
            "person": "Кайгермазов Марат Аскербиевич"
        },
        {
            "region": "Республика Мордовия",
            "person": "Абмаев Михаил Константинович"
        },
        {
            "region": "Республика Северная Осетия - Алания",
            "person": "Касаев Аслан Хаджимуратович"
        },
        {
            "region": "Республика Тыва",
            "person": "Крумкин Вадим Геннадьевич"
        },
        {
            "region": "Республика Хакасия",
            "person": "Шипалов Николай Фролович"
        },
        {
            "region": "Ростовская область",
            "person": "Кравченко Александр Алексеевич"
        },
        {
            "region": "Рязанская область",
            "person": "Аникин Виктор Сергеевич"
        },
        {
            "region": "Самарская область",
            "person": "Медведев Александр Николаевич"
        },
        {
            "region": "Санкт-Петербург",
            "person": "Науменко Сергей Владимирович"
        },
        {
            "region": "Саратовская область",
            "person": "Буйлова Наталия Владимировна"
        },
        {
            "region": "Свердловская область",
            "person": "Парамонов Александр Владимирович"
        },
        {
            "region": "Севастополь",
            "person": "Маньков Николай Николаевич"
        },
        {
            "region": "Смоленская область",
            "person": "Стерлягов Анатолий Александрович"
        },
        {
            "region": "Ставропольский край",
            "person": "Добриев Темирлан Русланович"
        },
        {
            "region": "Тамбовская область",
            "person": "Заворзаев Олег Николаевич"
        },
        {
            "region": "Тверская область",
            "person": "Аверьянов Андрей Гурьевич"
        },
        {
            "region": "Томская область",
            "person": "Испирян Севак Размикович"
        },
        {
            "region": "Тульская область",
            "person": "Лисин Алексей Михайлович"
        },
        {
            "region": "Тюменская область",
            "person": "<span style='font-size: 22px'>Руководитель</span> <br><br> Тихомиров Константин Евгеньевич <br><br> <span style='font-size: 22px'>Зам. руководителя</span> <br><br> Панарин Владимир Николаевич"
        },
        {
            "region": "Удмуртская Республика",
            "person": "Метелёв Сергей Рудольфович"
        },
        {
            "region": "Ульяновская область",
            "person": "Петров Павел Вячеславович"
        },
        {
            "region": "Хабаровский край",
            "person": "Кокорин Анатолий Васильевич"
        },
        {
            "region": "Ханты-Мансийский автономный округ - Югра",
            "person": "Алексеева Надежда Геннадьевна"
        },
        {
            "region": "Ханты-Мансийское городское отделение",
            "person": "Шевелева Татьяна Николаевна"
        },
        {
            "region": "Чеченская Республика",
            "person": "Алиев Бекхан Саид-Селахович"
        },
        {
            "region": "Чувашская Республика - Чувашия",
            "person": "Бубенцов Дмитрий Владимирович"
        },
        {
            "region": "Ярославская область",
            "person": "Охапкин Андрей Валерьевич"
        }
    ]
}


//async function GetData(url) {
//    const res = await fetch(url);
//    return await res.json();
//}

function LoadAgency() {
    try {
        const button = document.querySelector('.agency-button');
        button.addEventListener('click', () => {
            api("get-agency-data")
                .then(data => {
                const list = document.querySelector(".agency-list--region");
                list.innerHTML = '';
                for (let i = 0; i < data.length; i++) {
                    let item = JSON.parse(data[i].agency);
                    list.innerHTML+=`
                    <li class="agency-item">
                        <p class="agency-area">${item.region}</p>
                        <p class="agency-person">${item.person}</p>
                    </li>
                    `;
                }
                button.remove();
            })

            /*const list = document.querySelector(".agency-list--region");
            list.innerHTML = '';
            for (var item in data.agency) {
                var region = data.agency[item].region;
                var person = data.agency[item].person;
                list.innerHTML+=`
                <li class="agency-item">
                    <p class="agency-area">${region}</p>
                    <p class="agency-person">${person}</p>
                </li>
                `;
            }
            button.remove();*/
        })
    }
    catch(e) {}
};

export default LoadAgency;