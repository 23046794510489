import modal from "./modules/modal";
import parallax from "./modules/parallax";
import gallery from "./modules/gallery";
import accordion from "./modules/accordion";
import LoadAgency from "./modules/agency";
import forms from "./modules/forms";
import contactFormSubmit from "./modules/contacts";
import Hamburger from "./modules/hamburger";
import newsSearch from "./modules/newsSearch";
import tryConnection from "./modules/test_mysql_DELETE_THIS";
import certificates from "./modules/certificates";
import corporate from "./modules/corporate";

const data = {
  news: {
    Moscow: [
      {
        id: 1,
        date: "21.07.23",
        title:
          "Более 200 участников покажут разработки на экологических выставках в Москве",
        subtitle: "Ими станут представители РФ, КНР и других стран",
        description: `Более 200 участников из РФ, Китая и других стран примут участие в экологических выставках в Москве, говорится в пресс-релизе. Международные выставки технологий в области экологии EcwaExpo и WasteEcoExpo проходят в московском "Крокус Экспо". "Более 200 участников из России, Китая и других зарубежных стран, готовых к сотрудничеству, представят свои разработки и продукцию для быстрого и оптимального замещения ушедших с российского рынка западных компаний", – говорится в пресс-релизе.
                <br><br>
                В частности, участниками выставок станут представители бизнеса, профессионалы отрасли обращения с отходами, отрасли водоснабжения и водоотведения, а также сферы экологического машиностроения из Москвы, Курской, Ленинградской, Нижегородской, Новосибирской и Ростовской областей, а также Республики Татарстан. Ожидается, что в ходе мероприятия специалисты поднимут вопросы о новых методах повышения эффективности строительства экологических объектов, а также трансформации рынка отходов. Ранее радио Sputnik сообщило, что, по мнению вице-премьера РФ Виктории Абрамченко, в России не нужно "искусственно выращивать свою Грету Тунберг".`,
        img: "images/ad.webp",
        alt: "Международная экологическая выставка",
      },
      {
        id: 2,
        date: "21.07.23",
        title: "25 июля состоится экологический субботник",
        subtitle:
          "Уборка от мусора стихийного пляжа на реке Сок в Малой Царевщине",
        description: `25 июля состоится экологический субботник - уборка от мусора стихийного пляжа на реке Сок в Малой Царевщине🌲💧⛱🧹
                <br><br>
                В 11.00, 25 июля министерство лесного хозяйства, охраны окружающей среды природопользования Самарской области проводит экологический субботник по сбору и вывозу мусора на стихийном пляже в Малой Царевщине✅
                <br><br>
                Мы просим граждан не мусорить! Несложно взять с собой пакет для мусора и отнести свой же мусор на ближайшую контейнерную площадку🙏
                <br><br>
                Чистые пляжи и леса, реки и озёра - залог здоровья и благополучия граждан👌
                <br><br>
                Мешки, перчатки, инвентарь – в наличии👍`,
        img: "images/new2.webp",
        alt: "Трактор убирает мусор на свалке",
      },
      {
        id: 3,
        date: "30.08.23",
        title: `"Новая Экосистема" представляет фильм в области охраны окружающей среды`,
        subtitle: "Как поведение человека связано с загрязнением природы?",
        description: `АНО "Новая Экосистема" представляет очередной фильм проекта в области охраны окружающей среды "Визуальная экокультура".
          <br><br>
        "Экопсихология" - это видеофильм, в котором психолог-консультант, специалист по психосоматике,
        телесно-ориентированной терапии, арт-терапии и игротерапии Ольга Сенюкова разбирает особенности
        поведения человека, как это связано с загрязнением окружающей среды, рассказывает зачем люди мусорят
        и обучает техникам для отслеживания своих эмоций, мыслей и поведения. 
          <br><br>
        Съемочная группа: <br>
        Юлия Князева <br>
        Юлиан Поставничев-Харри <br>
        Сергей Чеченев <br>
        Екатерина Шпрыкова 
        <br><br>

        Руководитель проекта: Александр Князев

        <br><br>

        Подписывайся в соцсетях: <br>
        <a href="https://vk.com/newecosys" style="color:blue">АНО "Новая Экосистема" в Вконтакте</a> <br>
        <a href="https://vk.com/visualecoculture" style="color:blue">Проект "Визуальная экокультура" в Вконтакте</a> 

        <br><br>

        <a href="https://vk.com/dprie36" style="color:blue">Департамент природных ресурсов и экологии Воронежской области в Вконтакте</a>
        <br>
        <a href="https://vk.com/sobirator_vrn" style="color:blue">Экологический центр "Собиратор" в г. Воронеже в Вконтакте</a>
        <br>
        <a href="https://vk.com/reo_vrn" style="color:blue">Воронежское региональное отделение российского экологического общества Р в Вконтакте</a>
        <br>
        <a href="https://vk.com/rsbor_vrn" style="color:blue">Экологическое движение «РазДельный Сбор» Воронеж в Вконтакте</a>
        <br><br>

        Проект реализуется в рамках гранта губернатора Воронежской области Александра Викторовича Гусева,
        предоставленного в 2023 году Департаментом природных ресурсов и экологии Воронежской области на
        реализацию проектов в области охраны окружающей среды.`,
        img: "images/new3.webp",
        alt: "Ольга Сенюкова",
      },
    ],
  },
};

document.addEventListener("DOMContentLoaded", () => {
  modal(".footer-title", ".footer-modal", "modal-close");
  modal("[modal-img-trigger]", ".img-modal");
  gallery();
  parallax();
  accordion(".accordion", ".accordion-head", "accordion-content--active");
  LoadAgency();
  forms();
  contactFormSubmit();
  Hamburger();
  newsSearch(data);
  tryConnection();
  certificates();
  corporate();

  const newsTitles = document.querySelectorAll(".news-item-title");
  const width = document.documentElement.clientWidth;
  if (width > 767 && width < 1040) {
    newsTitles.forEach((title) => {
      title.textContent = `${title.textContent.substring(0, 64)}...`;
    });
  }
});

// var http = new XMLHttpRequest();
// http.open("GET", "../about.html");
// http.onreadystatechange = function() {
//     if (this.readyState == 4 && this.status == 200) {
//         var doc = new DOMParser().parseFromString(this.responseText, "text/html");
//         document.appendChild(doc.getElementById("ad").innerHTML);
//     }
// }
// http.send(null);
// import Highway from "@dogstudio/highway";
// import {Fade} from "./transition";

// const H = new Highway.Core({
//     transitions: {
//         default: Fade
//     }
// });
