const certificates = () => {
    const data = {
        "certificates": [
            // {
            //     "inn": 7716872816,
            //     "name": "ООО «ЭЦ СТИПКИ»",
            //     "startdate": "19.03.2020",
            //     "enddate": "18.03.2021",
            //     "img": "images/cert_stipki.webp"
            // },
            // {
            //     "inn": 5047175990,
            //     "name": `ООО "Саатотули-Восток"`,
            //     "startdate": "12.08.2020",
            //     "enddate": "12.08.2023 ",
            //     "img": "images/sert_saatotyli.webp"
            // },
            {
                "inn": 771806888490,
                "name": `ООО "Торговый дом Природа"`,
                "startdate": "17.05.2021",
                "enddate": "16.05.2024",
                "img": "images/cert_priroda.webp"
            }
            // {
            //     "inn": "3254388-6",
            //     "name": `FCW International Oy Ltd`,
            //     "startdate": "17.03.2022",
            //     "enddate": "16.03.2025",
            //     "img": "images/cert_fcw.webp"
            // },
        ] 
    }
    
    const showCertificate = (name,startdate,enddate,img,error,flag, data,inn,parent, btn,trigger) => {
        name.value = "";
        startdate.value = "";
        enddate.value = "";
        img.src = "";
        error.remove();
        btn.disabled = true;
        flag=true;
        for (const i in data.certificates) {
            const item = data.certificates[i];
            if (item.inn==inn.value) {
                flag=false;
                btn.disabled = false;
                name.value = item.name;
                startdate.value = item.startdate;
                enddate.value = item.enddate;
                img.src = item.img;
            }
        }
        if (flag) {
            parent.insertBefore(error,trigger);
        }
    }

    try {
        const inn = document.querySelector('.certificate-search');
        const startdate = document.querySelector('.certificate-startdate');
        const enddate = document.querySelector('.certificate-enddate');
        const img = document.querySelector('.modal-img');
        const btn = document.querySelector('[modal-img-trigger]');
        const name = document.querySelector('.certificate-orgname');
        const text = document.querySelector('.certificate-text');
        const error = document.createElement('div');
        error.textContent = "ИНН не найден. Попробуйте еще раз.";
        const trigger = document.querySelector('[search-trigger-button]');
        const parent = trigger.parentNode;
        error.style.color = "red";
        error.style.fontSize = "14px";
        inn.addEventListener('input', (e) => {
            e.target.value = e.target.value.replace(/[a-zа-я.//\\';`A-ZА-ЯёЁ=,!"№%:?*()+_~ ]/g,'');
        })
        let flag = true;
        inn.addEventListener('keypress', (e) => {
            if (e.key == "Enter") {
                showCertificate(name,startdate,enddate,img,error,flag, data,inn,parent,btn,trigger);
            }
        }) 

        trigger.addEventListener('click', (e) => {
            showCertificate(name,startdate,enddate,img,error,flag, data,inn,parent,btn,trigger);
        })
    }
    catch (e) {}
}

export default certificates;