function accordion(parentSelector, headSelector, active, paddings=40) {
    try {
        const parents = document.querySelectorAll(parentSelector);

        parents.forEach(parent => {

            const headers = parent.querySelectorAll(headSelector);
            headers.forEach((head) => {
                
                head.addEventListener('click', () => {
                    const content = head.nextElementSibling;
                    content.classList.toggle(active);
    
                    content.classList.contains(active) ? 
                        content.style.maxHeight = `${content.scrollHeight+paddings}px` : 
                        content.style.maxHeight = "0px";
                });
            })
        })
    }
    catch(e) {}
}
export default accordion;