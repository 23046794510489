const Hamburger = () => {
    const width = document.documentElement.clientWidth;
    const toggleContainer = document.querySelector('.header-close-container');
    const nav = document.querySelector('[data-nav-main]');
    const navs = document.querySelectorAll('nav');
    toggleContainer.addEventListener('click', (e) => {
        let target = e.target;
        if (target != toggleContainer) {
            target = target.parentElement;
        }
        const toggler = target.querySelector('button');
        if (toggler.classList.contains('header-close')) {
            toggler.classList.remove('header-close');
            toggler.classList.add('header-opened');
            navs.forEach(nav => nav.style.display = '');
        }
        else {
            toggler.classList.add('header-close');
            toggler.classList.remove('header-opened');
            nav.style.display = "block";
        }
    })

    const navLinks = document.querySelectorAll('[data-nav-main] .navigation-link');
    navLinks.forEach(link => {
        if (link.getAttribute('data-nav-about') != null) {
            link.addEventListener('click', e => {
                e.preventDefault();
                //for mobile and planchet
                nav.style.display = '';
                const curNav = document.querySelector('nav[data-nav-about]');
                curNav.style.display = 'block';
                curNav.querySelector('.navigation-back').addEventListener('click', e => {
                    nav.style.display = 'block';
                    curNav.style.display = '';
                }, {once: true});
            })
        }
    })
}

export default Hamburger;
