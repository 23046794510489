const corporate = () => {
  const data = {
    corporate: [
      {
        pdf: "./images/ktl/001.pdf",
        img: "./images/ktl/001.webp",
        title: "Выпуск №1",
      },
      {
        pdf: "./images/ktl/002.pdf",
        img: "./images/ktl/002.webp",
        title: "Выпуск №2",
      },
      {
        pdf: "./images/ktl/003.pdf",
        img: "./images/ktl/003.webp",
        title: "Выпуск №3",
      },
      {
        pdf: "./images/ktl/004.pdf",
        img: "./images/ktl/004.webp",
        title: "Выпуск №4",
      },
      {
        pdf: "./images/ktl/005.pdf",
        img: "./images/ktl/005.webp",
        title: "Выпуск №5",
      },
      {
        pdf: "./images/ktl/006.pdf",
        img: "./images/ktl/006.webp",
        title: "Выпуск №6",
      },
      {
        pdf: "./images/ktl/007.pdf",
        img: "./images/ktl/007.webp",
        title: "Выпуск №7",
      },
      {
        pdf: "./images/ktl/008.pdf",
        img: "./images/ktl/008.webp",
        title: "Выпуск №8",
      },
      {
        pdf: "./images/ktl/009.pdf",
        img: "./images/ktl/009.webp",
        title: "Выпуск №9",
      },
      {
        pdf: "./images/ktl/010.pdf",
        img: "./images/ktl/010.webp",
        title: "Выпуск №10",
      },
      {
        pdf: "./images/ktl/011.pdf",
        img: "./images/ktl/011.webp",
        title: "Выпуск №11",
      },
      {
        pdf: "./images/ktl/012.pdf",
        img: "./images/ktl/012.webp",
        title: "Выпуск №1 (12)",
      },
      {
        pdf: "./images/ktl/013.pdf",
        img: "./images/ktl/013.webp",
        title: "Выпуск №2 (13)",
      },
      {
        pdf: "./images/ktl/014.pdf",
        img: "./images/ktl/014.webp",
        title: "Выпуск №3 (14)",
      },
      {
        pdf: "./images/ktl/015.pdf",
        img: "./images/ktl/015.webp",
        title: "Выпуск №4 (15)",
      },
      {
        pdf: "./images/ktl/016.pdf",
        img: "./images/ktl/016.webp",
        title: "Выпуск №5 (16)",
      },
      {
        pdf: "./images/ktl/017.pdf",
        img: "./images/ktl/017.webp",
        title: "Выпуск №6 (17)",
      },
      {
        pdf: "./images/ktl/018.pdf",
        img: "./images/ktl/018.webp",
        title: "Выпуск №7 (18)",
      },
      {
        pdf: "./images/ktl/019.pdf",
        img: "./images/ktl/019.webp",
        title: "Выпуск №8 (19)",
      },
      {
        pdf: "./images/ktl/020.pdf",
        img: "./images/ktl/020.webp",
        title: "Выпуск №9 (20)",
      },
      {
        pdf: "./images/ktl/021.pdf",
        img: "./images/ktl/021.webp",
        title: "Выпуск №10 (21)",
      },
      {
        pdf: "./images/ktl/022.pdf",
        img: "./images/ktl/022.webp",
        title: "Выпуск №11 (22)",
      },
      {
        pdf: "./images/ktl/023.pdf",
        img: "./images/ktl/023.webp",
        title: "Выпуск №12 (23)",
      },
      {
        pdf: "./images/ktl/024.pdf",
        img: "./images/ktl/024.webp",
        title: "Выпуск №1 (24)",
      },
      {
        pdf: "./images/ktl/025.pdf",
        img: "./images/ktl/025.webp",
        title: "Выпуск №2 (25)",
      },
      {
        pdf: "./images/ktl/026.pdf",
        img: "./images/ktl/026.webp",
        title: "Выпуск №3 (26)",
      },
      {
        pdf: "./images/ktl/027.pdf",
        img: "./images/ktl/027.webp",
        title: "Выпуск №4 (27)",
      },
      {
        pdf: "./images/ktl/028.pdf",
        img: "./images/ktl/028.webp",
        title: "Выпуск №5 (28)",
      },
      {
        pdf: "./images/ktl/029.pdf",
        img: "./images/ktl/029.webp",
        title: "Выпуск №6 (29)",
      },
      {
        pdf: "./images/ktl/030.pdf",
        img: "./images/ktl/030.webp",
        title: "Выпуск №7 (30)",
      },
      {
        pdf: "./images/ktl/031.pdf",
        img: "./images/ktl/031.webp",
        title: "Выпуск №8 (31)",
      },
      {
        pdf: "./images/ktl/032.pdf",
        img: "./images/ktl/032.webp",
        title: "Выпуск №9 (32)",
      },
      {
        pdf: "./images/ktl/033.pdf",
        img: "./images/ktl/033.webp",
        title: "Выпуск №10 (33)",
      },
      {
        pdf: "./images/ktl/034.pdf",
        img: "./images/ktl/034.webp",
        title: "Выпуск №11 (34)",
      },
      {
        pdf: "./images/ktl/035.pdf",
        img: "./images/ktl/035.webp",
        title: "Выпуск №12 (35)",
      },
      {
        pdf: "./images/ktl/036.pdf",
        img: "./images/ktl/036.webp",
        title: "Выпуск №1 (36)",
      },
      {
        pdf: "./images/ktl/037.pdf",
        img: "./images/ktl/037.webp",
        title: "Выпуск №2 (37)",
      },
      {
        pdf: "./images/ktl/038.pdf",
        img: "./images/ktl/038.webp",
        title: "Выпуск №3 (38)",
      },
      {
        pdf: "./images/ktl/039.pdf",
        img: "./images/ktl/039.webp",
        title: "Выпуск №4 (39)",
      },
      {
        pdf: "./images/ktl/040.pdf",
        img: "./images/ktl/040.webp",
        title: "Выпуск №5 (40)",
      },
      {
        pdf: "./images/ktl/041.pdf",
        img: "./images/ktl/041.webp",
        title: "Выпуск №6 (41)",
      },
      {
        pdf: "./images/ktl/042.pdf",
        img: "./images/ktl/042.webp",
        title: "Выпуск №7 (42)",
      },
      {
        pdf: "./images/ktl/043.pdf",
        img: "./images/ktl/043.webp",
        title: "Выпуск №8 (43)",
      },
      {
        pdf: "./images/ktl/044.pdf",
        img: "./images/ktl/044.webp",
        title: "Выпуск №9 (44)",
      },
      {
        pdf: "./images/ktl/045.pdf",
        img: "./images/ktl/045.webp",
        title: "Выпуск №10 (45)",
      },
      {
        pdf: "./images/ktl/046.pdf",
        img: "./images/ktl/046.webp",
        title: "Выпуск №11 (46)",
      },
      {
        pdf: "./images/ktl/047.pdf",
        img: "./images/ktl/047.webp",
        title: "Выпуск №12 (47)",
      },
      {
        pdf: "./images/ktl/048.pdf",
        img: "./images/ktl/048.webp",
        title: "Выпуск №1 (48)",
      },
      {
        pdf: "./images/ktl/049.pdf",
        img: "./images/ktl/049.webp",
        title: "Выпуск №2 (49)",
      },
      {
        pdf: "./images/ktl/050.pdf",
        img: "./images/ktl/050.webp",
        title: "Выпуск №3 (50)",
      },
      {
        pdf: "./images/ktl/051.pdf",
        img: "./images/ktl/051.webp",
        title: "Выпуск №4 (51)",
      },
      {
        pdf: "./images/ktl/052.pdf",
        img: "./images/ktl/052.webp",
        title: "Выпуск №5 (52)",
      },
      {
        pdf: "./images/ktl/053.pdf",
        img: "./images/ktl/053.webp",
        title: "Выпуск №6 (53)",
      },
      {
        pdf: "./images/ktl/054.pdf",
        img: "./images/ktl/054.webp",
        title: "Выпуск №7 (54)",
      },
      {
        pdf: "./images/ktl/055.pdf",
        img: "./images/ktl/055.webp",
        title: "Выпуск №8 (55)",
      },
      {
        pdf: "./images/ktl/056.pdf",
        img: "./images/ktl/056.webp",
        title: "Выпуск №9 (56)",
      },
      {
        pdf: "./images/ktl/057.pdf",
        img: "./images/ktl/057.webp",
        title: "Выпуск №10 (57)",
      },
      {
        pdf: "./images/ktl/058.pdf",
        img: "./images/ktl/058.webp",
        title: "Выпуск №11 (58)",
      },
      {
        pdf: "./images/ktl/059.pdf",
        img: "./images/ktl/059.webp",
        title: "Выпуск №12 (59)",
      },
      {
        pdf: "./images/ktl/060.pdf",
        img: "./images/ktl/060.webp",
        title: "Выпуск №1 (60)",
      },
      {
        pdf: "./images/ktl/061.pdf",
        img: "./images/ktl/061.webp",
        title: "Выпуск №2 (61)",
      },
      {
        pdf: "./images/ktl/062.pdf",
        img: "./images/ktl/062.webp",
        title: "Выпуск №3 (62)",
      },
      {
        pdf: "./images/ktl/063.pdf",
        img: "./images/ktl/063.webp",
        title: "Выпуск №4 (63)",
      },
      {
        pdf: "./images/ktl/064.pdf",
        img: "./images/ktl/064.webp",
        title: "Выпуск №5 (64)",
      }
    ],
  };
  try {
    const parent = document.querySelector(".corporate-list");
    const btn = document.querySelector(".corporate-button");
    btn.addEventListener("click", (e) => {
      parent.innerHTML = "";
      for (const i in data.corporate) {
        const item = data.corporate[i];
        parent.innerHTML += `
            <a href="${item.pdf}" target="_blank" class="corporate-item">
                <img class="corporate-item-img" src="${item.img}" alt="">
                <p class="corporate-item-title">${item.title}</p>
            </a>`;
      }
      btn.remove();
    });
  } catch (e) {}
};

export default corporate;
