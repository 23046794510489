const isProduction = true; // need to create server-side config instead

function GetBaseURL() {
    if (isProduction) {
        return "https://nkeb.ru:3000/";
    }
    return "https://localhost:3000/";
}

async function GetData(path) {
    let response = await fetch(GetBaseURL() + path);
    return response.json();
}

export default GetData;
