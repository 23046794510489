function gallery() {

    try {
        const galleryPictures = document.querySelectorAll(".gallery-item"),
              galleryPicturesCount = galleryPictures.length;
        let curCount = 0;``
        galleryPictures[curCount].classList.add('gallery-item--active')
        
        setInterval(() => {
            galleryPictures[curCount].classList.remove('gallery-item--active');
            curCount<galleryPicturesCount-1 ? curCount++ : curCount = 0;
            galleryPictures[curCount].classList.add('gallery-item--active');
        }, 4000)
    }
    catch(e) {

    }
}

export default gallery;