function contactFormSubmit(form) {
    try {
        // name is not used so far? is it really necessary?
        let name = form.name.value;

        let email = form.email.value;
        let theme = form.theme.value;
        let message = form.subject.value;
        if (name.length == 0) {
        alert('Имя не должно быть пустым');
            return;
        }
        if (email.length == 0) {
        alert('Почта отправителя не должна быть пустой');
            return;
        }

        const msgReciever = 'info@nkeb.ru';

        // currently wont work properly, has to fix this later
        location.href = "mailto:"+msgReciever+"?cc="+email+"&subject="+theme+"&body="+message;
    }
    catch (e) {}
    
}

export default contactFormSubmit;