const newsSearch = (data) => {
  const getNewById = (id) => {
    for (var i in data.news.Moscow) {
      const item = data.news.Moscow[i];
      if (item.id == id) {
        return item;
      }
    }
  };

  const drawNew = () => {
    if (document.location.hash.indexOf("#") != -1) {
      const id = document.location.hash.split("#")[1];
      const newContainer = document.querySelector(".new");
      console.log(id);
      const item = getNewById(id);
      newContainer.innerHTML = `
            <img class="new-img" src="${item.img}" alt="${item.alt}">
            <h2 class="new-title">${item.title}</h2>
            <h3 class="new-subtitle">${item.subtitle}</h3>
            <p class="new-info">${item.date}</p>
            <p class="new-paragraph">${item.description}
            </p>
            `;
      document.querySelector("title").textContent = item.title;
    }
  };

  const search = document.querySelector(".all-news-search");
  try {
    const newsContainer = document.querySelector(".all-news");
    for (var i in data.news.Moscow) {
      const item = data.news.Moscow[i];
      newsContainer.innerHTML += `
            <a href="new#${item.id}"  class="all-news-container">
                <div class="all-news-img-container">
                    <img class="all-news-img" src="${item.img}" alt="${item.alt}">
                </div>
                <div class="all-news-topic-container">
                    <p class="all-news-title">${item.title}</p>
                    <p class="all-news-subtitle">${item.subtitle}</p>
                    <p class="all-news-date">${item.date}</p>
                </div>
            </a> `;
    }
  } catch (e) {}

  window.addEventListener("hashchange", () => {
    drawNew();
  });
  drawNew();
};

export default newsSearch;
