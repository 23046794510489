function modal(triggerSelector, modalSelector, closeSelector) {
    try {
        const modalTrigger = document.querySelector(triggerSelector),
            modal = document.querySelector(modalSelector);


        modalTrigger.addEventListener('click', () => {
            modal.classList.remove('modal-container-close');
        })

        modal.addEventListener('mousedown', (e) => {
            if (e.target == modal || e.target.classList.contains(closeSelector)) {
                modal.classList.add('modal-container-close');
            }
        })
    }
    catch (e) {}

}

export default modal;