import PostData from "../services/PostData";

function forms() {
    function clearInputs(form) {
        const inputs = form.querySelectorAll('input');
        const textareas = form.querySelectorAll('textarea');
        textareas.forEach(textarea => {
            textarea.value = "";
        })
        inputs.forEach(input => {
            input.value = "";
        })
    }

    const img = {
        success: "./images/success.png",
        failure: "./images/failure.png"
    }
    const message = {
        loading: 'Загрузка...',
        success: "Спасибо! Скоро мы с вами свяжемся",
        failure: "Что-то пошло не так...",
    };
    const forms = document.querySelectorAll('.modal-form');
    forms.forEach(form => {
        //CheckInputDigits(form);
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            setTimeout(() => form.style.display = 'none', 400);
    
            let statusImg = document.createElement('img');
            statusImg.style.width = "150px";
            statusImg.style.height = "150px;"
            statusImg.classList.add('animate', 'fadeInUp');
            statusImg.setAttribute('src', img.loading);
            setTimeout(() => form.parentNode.appendChild(statusImg),450);
    
            let statusMessage = document.createElement('div');
            statusMessage.textContent = message.loading;
            statusMessage.classList.add('success');
            setTimeout(() => form.parentNode.appendChild(statusMessage),450);
    
    
            const formData = new FormData(form);
            //const json = JSON.stringify(Object.fromEntries(formData));
            PostData("assets/server.php", formData)
            .then((text) => {
                console.log(text);
                statusImg.setAttribute('src', img.success);
                statusMessage.textContent = message.success;
            })
            .catch(() => {
                statusImg.setAttribute('src', img.failure);
                statusMessage.textContent = message.failure;
                
                
            })
            .finally(() => {
                setTimeout(() => {
                    clearInputs(form);
                    form.style.display = 'block';
                    statusMessage.remove();
                    statusImg.remove();
                }, 5000);
            });
        });
    }   
    );
}

export default forms;